@import '../../../styles/colors'
@import '../../../styles/variables.sass'

.corePlans
  max-width: $defaultWidth
  margin: 0 auto
  padding: 0 8px
  .custom-scrollbar 
    & *::-webkit-scrollbar 
      width: 20px 
  .loading-spinner-container
      width: 100%
      display: flex
      justify-content: center
      margin-top: 80px
      .spinner-container
        background-color: none

    
  .no-core-plans-message
      width: 100%
      display: flex
      flex-direction: column
      justify-content: center
      margin-top: 80px
      span
        font-size: 16px
        text-align: center
      p
        font-size: 20px
        text-align: center

  
  .scroll-container
     display: grid
     grid-template-columns: repeat(4, 1fr)
     grid-auto-rows: auto
     grid-row-gap: 48px
     grid-column-gap: 40px
     padding: 5px
     .card-container
        cursor: pointer
        width: 100%
        display: flex
        justify-content: center
        outline: 0.5px solid #003C77
        border-radius: 10px

        &:hover
            outline: #1F75BB 2px solid
            box-shadow: 0 6px 4px -3px rgba(0, 0, 0, 0.3), 4px 0 10px -4px rgba(0, 0, 0, 0.2)
     

     .card-corePlan
      border: none
      outline: none
     .card
      width: 274px
      height: 148px
      
     

.ant-select
  .ant-select-arrow
    width: 8px
    height: 8px
    background: url("./../../../../public/assets/icons/union-black.svg") no-repeat center center / 8px auto
    svg
      display: none
    margin-left: -56px

.ant-modal-close
  display: block
  width: 30px
  height: 30px
  opacity: 1
  top: 16px
  right: 25px
  &:before,
  &::after
    content: ""
    display: block
    width: 1px
    height: 17px
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    margin: auto
    background: $lennar-brand-blue
    transform: rotate(45deg)
  &::after
    transform: rotate(-45deg)
  span
    display: none

@media (max-width: 1252px)
  .corePlans .scroll-container
    grid-template-columns: repeat(3, 1fr)


@media (max-width: 932px)
  .corePlans .scroll-container
      grid-template-columns: repeat(2, 1fr)


@media (max-width: 613px)
  .corePlans .scroll-container
    display: flex
    flex-direction: column
    align-items: center

.ant-select-dropdown
  z-index: 1300