@import '../../../../../styles/colors.sass'

.group-properties
  font-family: Inter
  margin-bottom: 1rem

.group-properties-main
  padding: 1rem 2rem 1rem 2rem

  border-bottom: 1px solid #D5D7DA

  .list_item
     display: flex
     justify-content: space-between
     font-size: 14px
     font-weight: 400

  .title
    margin-bottom: 1rem
    color: #000
    font-weight: 500
    font-size: 16px

.group-properties-secondary
  padding: 1rem 2rem 1rem 2rem

  .secondary_title
    display: block
    margin-bottom: 0.5rem
    color: #000
    font-weight: 500
    font-size: 14px

  .list_item
    margin-bottom: 10px
    color: #6A6A6A
    font-weight: 400
    font-size: 14px

