@import '../../../../styles/colors'

$field-name-width: 200px
$field-input-width: 64px
$mep-field-input-width: 180px
$color-field-name-width: 176px
$color-field-input-width: 66px
$walls-field-name-width: 220px

.settings-tab
  .sub-category
    padding-bottom: 40px

  .mep-category-settings .MuiInputLabel-root:first-child
    min-width: $mep-field-input-width
  .sub-category-name
    font-family: Inter
    font-size: 14px
    font-weight: 600
    line-height: 20px
    margin-bottom: 10px
    min-height: 20px
  .sub-category-break
    height: 30px
  .setting-row, .color-row
    display: flex
    align-items: flex-end
    margin-bottom: 10px
    min-height: unset
    width: unset
    color: $lennar-brand-blue

    .setting-label
      min-width: $field-name-width
    .color-label
      min-width: $color-field-name-width

    .MuiInputLabel-root, .setting-unit p
      font-size: 12px
      font-weight: 400
      line-height: 20px
    .MuiInputLabel-root
      display: inline
      text-align: left
      padding: 0 4px 0 0
      font-family: Inter
      color: $lennar-dark-blue-2

    .setting-unit
      margin: 0 
    .setting-unit p
      color: $default-medium-gray-2
      padding: 0 0 0 4px
      font-family: "DM Sans"
      font-size: 10px
      font-style: normal
      font-weight: 400
      line-height: 20px
    .setting-input, .color-input
     .MuiInputBase-root
      width: $field-input-width
      padding: 0 0 0 4px
      height: 20px !important
      min-width: unset
      border-radius: 1px
      font-family: "DM Sans"
      font-size: 10px
      font-style: normal
      font-weight: 500
      line-height: 20px
      input
       padding: 2px 4px
       color: $basic-dark-green
     width: $field-input-width
  .color-row
    position: relative
    .color-rectangle
      position: absolute
      left: 145px
      height: 20px
      min-width: unset
      width: 20px
      padding: 0
      border: none
      visibility: visible
      border-radius: 10px
    .MuiInputBase-input
      width: $color-field-input-width
  .walls .setting-label
    min-width: $walls-field-name-width

  .chrome-picker> div:last-child > div:last-child
    display: none !important

form
  .roofDiagram
    margin-left: 34px
  .setting-img
    margin-left: -58px

#error-tooltip
  .MuiTooltip-tooltip
    color: $white
    white-space: nowrap
    background-color: $red
    font-size: 10px
    border-radius: 2px
    width: unset
    padding: 2px 4px
    margin: 2px 0 0 0

#setting-tooltip,
#walls-setting-tooltip,
#color-tooltip
  .MuiTooltip-tooltip
    margin: 5px 0 0 0
    .tooltip-title
      display: flex
      align-items: end
      margin-bottom: 8px
      border-bottom: 1px solid #D9D9D9
      svg
        width: 20px
        margin-right: 8px
      span
        margin-bottom: 6px
        font-family: Inter
        font-size: 10px
        font-style: normal
        font-weight: 500
        line-height: 120%
    .tooltip-description
      font-family: Inter
      font-size: 10px
      font-style: italic
      font-weight: 400
      line-height: 120%
#setting-tooltip
  max-width: calc($field-name-width + $field-input-width)
#color-tooltip
  max-width: calc($color-field-name-width + $color-field-input-width)
#walls-setting-tooltip
  max-width: calc($walls-field-name-width + $field-input-width)