@import '../../../../styles/colors'

#settings .MuiDialog-paper
  width: 1061px !important
  max-width: 1061px !important
  max-height: 84vh !important
  padding: 0

  .close-button
    position: absolute,
    right: 12px,
    top: 26px,

  .setting-tabs
    margin-right: 30px

  .MuiTabs-flexContainer
    max-height: 42px

    .MuiTab-root
      margin: -12px 0 -12px 0

  .MuiTypography-h4
    border-width: 0
    margin-top: 20px
    padding: 0

  .MuiDialogContent-root
    padding: 0px 4px 29px 31px
    overflow: hidden

  .settings-tab
   margin-top:36px
   height: calc(84vh - 212px)
   display: flex
   flex-direction: column
  .settings-panel
   padding: 0 0 8px 10px
   overflow-x: hidden
   overflow-y: auto
   flex-grow: 1

  .buttons-row
    justify-content: space-between
    width: 100%
    .left-buttons
      align-self: flex-start
    .right-buttons
      display: flex

  .buttons-row
    margin-top: 30px
    padding-right: 30px
    float: right
    .MuiButton-root:not(:last-child), .ant-btn:not(:last-child)
      margin-right: 30px
  div[id$="parameters"]
    .buttons-row
  
.ant-btn
  border-radius: 4px
  border-width: 1px

.buttons-row .MuiButton-root,
.confirm-dialog .MuiButton-root,
.buttons-row .ant-btn
  font-family: "DM Sans"
  font-size: 14px
  font-weight: 400
  font-style: normal
  line-height: normal
  padding: 6px 14px
  height: 48px
  width: 123px
  text-transform: inherit
  color: black
  border: 1px solid $lennar-brand-blue
  &:hover
   color: white
   background-color: $lennar-light-blue
   border: 1px solid $lennar-light-blue
.buttons-row .left-button
  width: 142px
  color: $lennar-brand-blue
  border: 1px dashed $lennar-brand-blue
  &:hover
   border: 1px dashed $lennar-dark-blue
.buttons-row .submit, .confirm-dialog .submit
  color: white
  background-color: $lennar-brand-blue
  &:hover
   background-color: $lennar-light-blue

.ant-modal-mask, .ant-modal-wrap
  z-index: 1300
