// Brand colors
$default-dark-gray: #002645
$default-medium-gray-1: #d8d8d8
$default-medium-gray-2: #bfbfbf
$default-light-gray-1: #fafafa
$default-light-gray-2: #efefef
$default-light-gray-3: #ebebeb
$gray: #E5E5E5
$white: #fff

$basic-dark-green: #0D2727


$lennar-text: #002645
$lennar-outlines: #002645
$lennar-menu-buttons: #003C77
$lennar-menu-highlight: #1F75BB
$lennar-outlines: #1F75BB
$lennar-text-white: #FFFFFF
$lennar-icon-fill: #002645

$lennar-button-selected: #003C77
$lennar-button-unselected: #FFFFFF
$lennar-primary-button-unselected: #002645
$lennar-button-hover: #1F75BB
$lennar-button-frame: #002645

$lennar-tooltip-background: #1F75BB

$lennar-dark-blue: #002645
$lennar-brand-blue: #003C77
$lennar-light-blue: #1F75BB
$lennar-dark-blue-2: #012827



$teal: #7EC2B6
$orange: #E06B39
$red: #E04242
$red2: #FF3B47

$gray1: #7B7B7B
$gray2: #F1F1F1
$gray3: #F6F6F6
$gray4: #959595
$gray5: #a9a9a9
$gray6: #d9d9d9

// Redefine Ant variables here
html
  --ant-primary-color: #005DAA
  --ant-primary-color-hover: #1F75BB
  --ant-primary-6: #c291e2
  --ant-primary-3: #6a6a6a
  --ant-primary-4: #0d2727



// New design

$blue-600: #003C77 //primary dark
$blue-500: #005DAA //primary secondary
$blue-400: #1F75BB //Link/info
$blue-300: #C4E3FE //focus/select
$blue-200: #E0F1FE //hover/highlight
$blue-100: #F3FAFF //BG

$black: #000000
$white: #ffffff

$grey-600: #666666 //Secondary Text
$grey-500: #858585 //Dark grey 2
$grey-400: #43434359 //Disabled state
$grey-300: #0000001F //Border/divider
$grey-200: #43434317 //Hover/highlights
$grey-100: #4343430F //BG
$grey-50: #F5F5F5BF //BG

$green-600: #1B5E20 //Success dark
$green-500: #117F27 //Success
$green-400: #117F27A6 
$green-300: #117F2740
$green-200: #117F2726
$green-100: #117F270F //BG

$warning-600: #AF5401 //ext
$warning-500: #E38D0D //Text/icons
$warning-400: #E38D0D99 
$warning-300: #E38D0D47
$warning-200: #E38D0D21 //BG
$warning-100: #E38D0D0F //BG

$alert-600: #940D0F //Red
$alert-500: #C72525
$alert-400: #C7252580 
$alert-300: #C7252559
$alert-200: #C7252526 
$alert-100: #C725250F 
