.info-button
  width: 24px
  height: 24px
  border-radius: 50%
  background-color: white
  border: 1px solid black
  display: flex
  align-items: center
  justify-content: center
  padding: 2px

  &:hover
    background-color: #f5f5f5

.info-icon
  color: #1c1b1f
  font-size: 16px

.popper-content
  padding: 8px 12px
  background: white
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15)
  border-radius: 6px
  width: 180px

.roof-shapes 
  font-family: Inter
  font-weight: 600
  font-size: 12px
  line-height: 18px
  letter-spacing: 0%
  color: #000000

.roof-details 
  font-family: Inter
  font-weight: 400
  font-size: 12px
  line-height: 18px
  letter-spacing: 0%
  color: #000000


.info-section
    min-width: 140px
    height: 36px
    display: flex
    flex-direction: column
    justify-content: center
    margin-bottom: 10px
    
    &:first-child
        margin-top: 20px

.manage-tab .item .btn-info
    right: 15px
    position: absolute
    top: 18px