@import '~antd/dist/antd.css'
@import '~antd/dist/antd.variable.min.css'
@import 'react-toastify/dist/ReactToastify.css'
@import './styles/variables.sass'
@import './styles/fonts.sass'
@import './styles/colors.sass'
@import './styles/buttons.sass'
@import './styles/form-elements'
@import './styles/icons.sass'


*
  box-sizing: border-box

body
  margin: 0
  color: $lennar-brand-blue
  font-family: $default-font

h1,
h2,
h3,
h4,
h5,
h6
  font-family: $font-heading
  font-weight: 700
  margin: 0 0 40px 0
  line-height: 1

h1
  font-size: 32px
  line-height: 64px
  margin: 0 0 40px 0
h4
  font-size: 12px
  line-height: 16px
  padding: 8px
  border-width: 0 0 1px 0
  border-style: solid
  border-color: $lennar-brand-blue

.ant-tooltip
  z-index: 1300

.main-content
  width: 100vw
  height: 100vh
  overflow: auto

.Toastify__toast
  padding: 8px 16px
  font-weight: 500
  font-size: 10px
  line-height: 20px
  border-radius: 0
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)
  .Toastify__toast-body
    padding: 0
    margin: 0
  .Toastify__toast-icon
    display: none
.Toastify__toast-container--top-right
  top: 100px
.Toastify__toast-theme--dark
  background: $white
.Toastify__toast--success
  color: $lennar-brand-blue
  border: 1px solid $lennar-brand-blue
  svg
    fill: $lennar-brand-blue
.Toastify__progress-bar--error
  background: $lennar-brand-blue
.Toastify__toast--error
  border: 1px solid $red
  color: $red
  svg
    fill: $red
.Toastify__progress-bar--error
  background: $red
.Toastify__toast--info
  color: $teal
  border: 1px solid $teal
  svg
    fill: $teal
.Toastify__progress-bar--info
  background: $teal
.Toastify__toast--warning
  color: $orange
  border: 1px solid $orange
  svg
    fill: $orange
.Toastify__progress-bar--warning
  background: $orange

.ant-upload.ant-upload-select
  width: 100%

.version-tooltip
  .ant-tooltip-inner
    min-height: 0
    padding: 0 22px 0 5px
  .version-label
    display: block
    font-size: 14px
    line-height: 20.83px

.version-item
  cursor: pointer
  color: $lennar-text-white
  font-size: 14px
  font-weight: 500
  line-height: 20px
  &.ant-tooltip-open
    background: none


.ScrollbarsCustom-Track
  background: transparent !important
  .ScrollbarsCustom-Thumb
    background: $default-medium-gray-2 !important

.ScrollbarsCustom-TrackY
  width: 7px !important
.ScrollbarsCustom-TrackX
  height: 4px !important

.ant-tabs-content
.ant-tabs-content-top
.ant-tabs-tabpane
  height: 100%

.row-buttons
  text-align: left